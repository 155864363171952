<template>
  <div class="wrap" v-if="isShow">
    <div class="wrap__confirm">
      <div class="wrap__confirm__text">绑定成功~</div>
      <van-button
        round
        type="primary"
        class="wrap__confirm__button"
        @click="toHome"
        >回到首页</van-button
      >
    </div>
  </div>
  <van-empty image="error" description="链接失效或错误" v-else />
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import { Toast } from "vant";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isShow = ref(false);
    const toHome = () => {
      router.push({ name: "home" });
    };
    onMounted(() => {
      const email = route.query.email;
      const token = route.query.token;
      if (email && token) {
        axios({
          url: "https://dc-news-api.kakkk.net/api/v1/email/confirm",
          method: "post",
          data: {
            email: email,
            token: token,
          },
        }).then((res) => {
          console.log(res.data);
          if (res.data.code === 0) {
            isShow.value = true;
          } else {
            Toast.fail('链接失效或错误');
          }
        });
      }
    });
    return { isShow, toHome };
  },
};
</script>

<style lang="less" scoped>
.wrap {
  height: 100vh;
  padding: 20vh 0;
  &__confirm {
    text-align: center;
    &__text {
      font-size: 50px;
      letter-spacing: 5px;
      line-height: 55px;
      color: #0984e3;
      padding: 40px 70px;
    }
    &__button {
      width: 160px;
      height: 40px;
    }
  }
}
</style>